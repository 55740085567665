import React, { useState, useRef } from "react";
import { Link } from "gatsby";
import Layout from "components/layout";
import SEO from "components/seo";
import GetInTouch from "components/getInTouch";

function Translators(props) {
  const {
    pageContext: {
      data: { data },
    },
  } = props;

  return (
    <Layout>
      <SEO
        title={data.seo_project_title}
        description={data.seo_project_meta_desc}
        keywords={data.seo_project_meta_keywords}
        slug={data.slug}
      />
      <div className="bg-midBlue pt-[60px] pb-[100px]">
        <h1 className="text-46 leading-[60px] text-white sm:text-center font-secondary font-semibold px-4 xl:px-0 mb-14 lg:mb-20">
          {data.title_x || data.seo_h1}
        </h1>
        <div className="w-11/12 xl:w-full max-w-6xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
            <div className="col-span-3">
              <div className="grid grid-cols-1 lg:grid-cols-2 mb-14">
                <div>
                  <h2 className="text-3xl text-white font-secondary mb-4">
                    My Name
                  </h2>
                  <p className="text-24 leading-7 text-white font-opensans mb-14">
                    {data.translator_name}
                  </p>
                  <h2 className="text-3xl text-white font-secondary mb-4">
                    I am from
                  </h2>
                  <p className="text-24 leading-7 text-white font-opensans mb-14">
                    {data.translator_place}
                  </p>
                  <h2 className="text-3xl text-white font-secondary mb-4">
                    My areas of expertise
                  </h2>
                  <p className="text-24 leading-7 text-white font-opensans mb-14">
                    {data.translator_service}
                  </p>
                </div>
                <div>
                  <h2 className="text-3xl text-white font-secondary mb-4">
                    About me
                  </h2>
                  <div
                    className="text-24 leading-8 text-white font-opensans"
                    dangerouslySetInnerHTML={{
                      __html: data.seo_project_content
                    }}
                  />
                </div>
              </div>
              <div className="w-full bg-GlobalContact bg-cover bg-no-repeat mx-auto py-[20px] px-6 text-white">
                <div className=" flex flex-col lg:flex-row md:items-center justify-around">
                  <p className="text-[26px] text-center sm:text-left font-secondary font-medium w-full">
                    Want to contact and start working with me?
                  </p>
                  <div className="my-8 md:my-0 w-full pt-4 lg:pt-0 lg:w-[30%] flex justify-center mx-auto">
                    <Link
                      to="/contactus.php"
                      className="font-opensans font-bold text-17 text-center uppercase rounded-full bg-midBlue text-white cursor-pointer  py-4 px-8 w-[253px] hover:bg-[#012A68]"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:border-l sm:border-white sm:pl-12 lg:pl-0 mt-16 lg:mt-0">
              <p className="text-lightBlue text-3xl pb-8 font-primary lg:text-center">
                Why choose us
              </p>
              <div className="sm:w-9/12 lg:text-center mb-7">
                <img
                  src="https://tomedes.gumlet.io/frontend/images/why-us-img/24-7-human-support.svg"
                  alt=""
                  className="m-auto inline-block w-20"
                />
                <p className="text-lg font-primary text-white mt-4">
                  24/7 Human Support
                </p>
              </div>
              <div className="sm:w-9/12 lg:text-center mb-7">
                <img
                  src="https://tomedes.gumlet.io/frontend/images/why-us-img/quality-guaranteed-color.svg"
                  alt=""
                  className="m-auto inline-block w-20"
                />
                <p className="text-lg font-primary text-white mt-4">
                  1 Year Guarantee
                </p>
              </div>
              <div className="sm:w-9/12 lg:text-center mb-7">
                <img
                  src="https://tomedes.gumlet.io/frontend/images/why-us-img/50000-business.svg"
                  alt=""
                  className="m-auto inline-block w-20"
                />
                <p className="text-lg font-primary text-white mt-4">
                  95,000 Business Customers
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GetInTouch />
    </Layout>
  );
}

export default Translators;
